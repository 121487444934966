
import id3Logo from "./assets/logos/id3-logo.png"
import photosLogo from "./assets/logos/photos-logo.png"

function App() {
  return (
    <main className="bg-background">
      <div className="min-h-screen flex items-center">
        <div className="container mx-auto">
          <div className="grid md:grid-cols-2 gap-4">
            <a
              className="hover:animate-pulse" 
              href="https://photos.analiabutos.com.ar"
            >
              <img src={photosLogo} alt=""/>
            </a>
            <a 
              className="hover:animate-pulse"
              href="https://id3.analiabustos.com.ar"
            >
              <img src={id3Logo} alt=""/>
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}

export default App;
